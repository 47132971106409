<template>
  <div>
    <v-dialog
      id="customize"
      ref="custom"
      v-model="show"
      width="400"
    >
      <v-container class="grey lighten-5">

        <!-- <validation-observer
          ref="observer"
        > -->
        <v-form
          ref="form"
        >
          <div class="align-center">
            <div class="signInHeader">
              Welcome to {{ $store.state.outlet.outletInfo.name }}!
            </div>
            <div class="signInContent">
              Enter your mobile number to get OTP to sign-in.
            </div>
          </div>
          <!-- <validation-provider
              v-slot="{ errors }"
              name="Mobile"
              rules="required"
            > -->
          <!-- <v-text-field
                v-model="userData.user.user_name"
                label="Mobile"
                :error-messages="errors[0]"
                required
                type="number"
              /> -->
          <!-- </validation-provider> -->
          <vue-tel-input-vuetify
            v-model="userData.user.user_name"
            :error="phone.number != '' && !phone.valid"
            :disabled="!inputDisable"
            class="phoneNUmberInput"
            @input="onInput"
            @country-changed="handleCountryChange"
          />

          <!-- <v-row justify="center">
              <v-col cols="6">
                <div
                  v-if="phone.number"
                  style="color: #e83e8c"
                >
                  <span>
                    Number:
                    <strong>{{ phone.number }}</strong>,&nbsp;
                  </span>
                  <span>
                    Is valid:
                    <strong>{{ phone.valid }}</strong>,&nbsp;
                  </span>
                  <span>
                    Country:
                    <strong>{{ phone.country }}</strong>
                  </span>
                </div>
              </v-col>
            </v-row> -->
          <div class="text-center">
            <v-btn
              class="sendOtp"
              :disabled="!phone.valid"
              :loading="loading"
              @click="sendOtp"
            >
              SEND OTP
            </v-btn>
          </div>
        </v-form>
        <!-- </validation-observer> -->
      </v-container>
    </v-dialog>

    <!-- Address Dialog-->

    <v-dialog
      id="customize"
      ref="custom"
      v-model="isOtp"
      width="400"
    >
      <v-container class="grey lighten-5">
        <div class="align-center">
          <div class="signInHeader">
            Welcome to {{ $store.state.outlet.outletInfo.name }}!
          </div>
          <div class="signInContent">
            Enter the code sent to your phone to continue.
          </div>
        </div>
        <otp-input
          ref="otpInputRef"
          input-classes="otp-input"
          @on-complete="handleOnComplete"
          @on-change="handleOnChange"
        />
        <!-- <v-text-field
          ref="otpInputRef"
          v-model="userOtp"
          input-classes="otp-input"
          class="otpInput"
        /> -->
        <h3 class="error-msg">
          {{ errors.otp }}
        </h3>

        <div class="text-center">
          <v-btn
            class="sendOtp"
            :loading="loading"
            @click="handleOnComplete"
          >
            SIGN IN
          </v-btn>
        </div>
      </v-container>
    </v-dialog>

    <v-dialog
      id="customize"
      ref="custom"
      v-model="isUpdate"
      width="500"
      persistent
    >
      <v-container class="grey lighten-5">
        <validation-observer
          ref="signUpobserver"
        >
          <v-form
            ref="form"
            class="nameDial"
          >
            <!-- <validation-provider
              v-slot="{ errors }"
              name="First Name"
              rules="required"
            >

              <v-text-field
                v-model="userData.user.first_name"
                label="First Name"
                required
                :error-messages="errors[0]"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Last Name"
              rules="required"
            >

              <v-text-field
                v-model="userData.user.last_name"
                label="Last Name"
                :error-messages="errors[0]"
              />
            </validation-provider> -->

            <validation-provider
              v-slot="{ errors }"
              name="Name"
              rules="required"
            >

              <v-text-field
                v-model="userData.user.name"
                label="Name"
                required
                :error-messages="errors[0]"
              />
            </validation-provider>

            <v-btn
              class="button-add-address"
              @click="updateDetails"
            >
              Submit
            </v-btn>
          </v-form>
        </validation-observer>

      </v-container>
    </v-dialog>

  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { userService } from '../api/userService'
import otpInput from './otp/OtpInput.vue'
import { useRouter } from '../utils/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    otpInput,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    isToCart: {
      type: Boolean,
      required: false,
      default: false,
    },
    orderType: {
      type: String,
      required: false,
      default: 'dinein',
    },
  },
  setup(props, context) {
    const userData = ref({
      user: {
        first_name: '', last_name: '', user_name: '', name: '',
      },
    })

    const errors = ref({ otp: '' })

    const isOtp = ref(false)

    const activeUser = ref({})

    const isUpdate = ref(false)

    const inputDisable = ref(false)

    const { router } = useRouter()

    const loading = ref(false)

    const userOtp = ref('')

    const otpInputRef = ref(null)

    const signUpobserver = ref(null)

    const navigateToCart = ref(props.isToCart)

    watch(() => props.isToCart, isToCart => {
      navigateToCart.value = isToCart
    })

    const phone = ref({
      number: '',
      valid: false,
      country: undefined,
    })

    const signIn = () => {
      //   isOtp.value = true
      const data = { user_name: activeUser.value.user_name }
      userService.signInRequest(data).then(() => {
        // activeUser.value = res.data
        // isOtp.value = true
        // store.state.user.isLoggedIn = true
      })
    }

    const sendOtp = () => {
      errors.value.otp = null
      userOtp.value = ''
      const data = { user_name: userData.value.user.user_name, phone: phone.value.number, outlet_id: router.currentRoute.params.id }
      loading.value = true
      userService.otpRequest(data).then(res => {
        // store.state.user.activeUser = res.data
        loading.value = false
        isOtp.value = false
        activeUser.value = res.data
        localStorage.setItem('phone', phone.value.number)
        context.emit('is-sign-in-active')
        isOtp.value = true
        if (otpInputRef.value) {
          otpInputRef.value.clearInput()
        }
        userData.value.user.user_name = ''
        // store.state.user.isLoggedIn = true
      })
    }

    const handleOnComplete = otpInputVal => {
      userOtp.value = otpInputVal
      // eslint-disable-next-line radix
      const otp = parseInt(userOtp.value)
      if (otp === activeUser.value.otp) {
        store.state.user.isLoggedIn = true
        store.state.user.activeUser = activeUser.value
        isOtp.value = false
        signIn()
        if (activeUser.value.status === 'false') {
          isUpdate.value = true
        }
        if (activeUser.value.status === 'true' && navigateToCart.value) {
          // router.push({ name: 'cart' })
          context.emit('checkout')
        }
      } else {
        errors.value.otp = 'otp mismatch'
      }
    }

    const handleOnChange = () => {
      errors.value.otp = null
    }

    const updateDetails = () => {
      signUpobserver.value.validate().then(resp => {
        if (resp) {
          const data = { user: { first_name: userData.value.user.first_name, last_name: userData.value.user.last_name, name: userData.value.user.name } }
          userService.updateRequest(data).then(res => {
            store.state.user.activeUser = res.data
            isUpdate.value = false
            router.push({ name: 'cart' })
          })
        }
      })
    }

    const handleCountryChange = () => {
      inputDisable.value = true
    }

    const onInput = (formattedNumber, { number, valid, country }) => {
      phone.value.number = number.international
      phone.value.valid = valid
      phone.value.country = country && country.name
    }

    return {
      signIn,
      sendOtp,
      isOtp,
      required,
      email,
      userData,
      isUpdate,
      errors,
      inputDisable,
      loading,
      handleOnComplete,
      handleOnChange,
      updateDetails,
      handleCountryChange,
      phone,
      onInput,
      userOtp,
      otpInputRef,
      signUpobserver,
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('is-sign-in-active')
        }
      },
    },
  },

}
</script>
