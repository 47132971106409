var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-overlay",
    { staticClass: "sheet-overlay", attrs: { value: _vm.blockStore } },
    [
      _c("div", [
        _c("img", {
          staticClass: "srvc-svg",
          attrs: { src: require("../assets/images/service_unavailable.svg") }
        })
      ]),
      _c("p", [_vm._v("Service not available at this moment")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }