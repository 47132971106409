export default {
  // Endpoints
  registration_request: 'registration',
  active_user_request: 'registration/get_active_user',
  signout_request: 'registration/sign_out',
  signin_request: 'registration/sign_in',
  save_address_request: 'registration/save_address',
  get_store_details: 'store/get_details',
  otp_request: 'registration/send_otp',
  verify_otp: 'registration/verify_otp',
  update_request: 'registration/update_details',
  place_order: 'store/place_order',
  set_outlet: 'store/set_outlet',

}
