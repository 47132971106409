var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "align-items": "center" } },
    [
      _vm.inputType === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            ref: "input",
            class: _vm.inputClasses,
            attrs: {
              min: "0",
              max: "9",
              maxlength: "1",
              pattern: "[0-9]",
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, null) > -1
                : _vm.model
            },
            on: {
              input: _vm.handleOnChange,
              keydown: _vm.handleOnKeyDown,
              paste: _vm.handleOnPaste,
              focus: _vm.handleOnFocus,
              blur: _vm.handleOnBlur,
              change: function($event) {
                var $$a = _vm.model,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.model = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.model = $$c
                }
              }
            }
          })
        : _vm.inputType === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            ref: "input",
            class: _vm.inputClasses,
            attrs: {
              min: "0",
              max: "9",
              maxlength: "1",
              pattern: "[0-9]",
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.model, null) },
            on: {
              input: _vm.handleOnChange,
              keydown: _vm.handleOnKeyDown,
              paste: _vm.handleOnPaste,
              focus: _vm.handleOnFocus,
              blur: _vm.handleOnBlur,
              change: function($event) {
                _vm.model = null
              }
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            ref: "input",
            class: _vm.inputClasses,
            attrs: {
              min: "0",
              max: "9",
              maxlength: "1",
              pattern: "[0-9]",
              type: _vm.inputType
            },
            domProps: { value: _vm.model },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.model = $event.target.value
                },
                _vm.handleOnChange
              ],
              keydown: _vm.handleOnKeyDown,
              paste: _vm.handleOnPaste,
              focus: _vm.handleOnFocus,
              blur: _vm.handleOnBlur
            }
          }),
      !_vm.isLastChild && _vm.separator
        ? _c("span", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.separator) } })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }