var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "otp-align my-5", staticStyle: { display: "flex" } },
      [
        _vm.inputType === "password"
          ? _c("input", {
              staticStyle: { display: "none" },
              attrs: { autocomplete: "off", name: "hidden", type: "text" }
            })
          : _vm._e(),
        _vm._l(_vm.numInputs, function(item, i) {
          return _c("SingleOtpInput", {
            key: i,
            attrs: {
              focus: _vm.activeInput === i,
              value: _vm.otp[i],
              separator: _vm.separator,
              "input-type": _vm.inputType,
              "input-classes": _vm.inputClasses,
              "is-last-child": i === _vm.numInputs - 1,
              "should-auto-focus": _vm.shouldAutoFocus
            },
            on: {
              "on-change": _vm.handleOnChange,
              "on-keydown": _vm.handleOnKeyDown,
              "on-paste": _vm.handleOnPaste,
              "on-focus": function($event) {
                return _vm.handleOnFocus(i)
              },
              "on-blur": _vm.handleOnBlur
            }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }