/* eslint-disable no-use-before-define */
// import axios from 'axios'
import axios from '../libs/axios'

import apiConfig from './apiConfig'

// axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.defaults.withCredentials = true

// const api = axios.create({
//   baseURL: process.env.VUE_APP_API_URL,
//   withCredentials: true,
// })

// eslint-disable-next-line import/prefer-default-export
export const userService = {
  // eslint-disable-next-line no-use-before-define
  registrationRequest,
  activeUserRequest,
  signOutRequest,
  updateAddress,
  signInRequest,
  getStoreDetails,
  otpRequest,
  updateRequest,
  placeOrder,
  setOutlet,
}

function registrationRequest(data) {
  return axios.post(
    apiConfig.registration_request,
    data,
  )
}
function updateRequest(data) {
  return axios.put(
    apiConfig.update_request,
    data,
  )
}

function activeUserRequest() {
  return axios.get(
    apiConfig.active_user_request,
  )
}

function signOutRequest() {
  return axios.get(
    apiConfig.signout_request,
  )
}
function signInRequest(data) {
  return axios.post(
    apiConfig.signin_request,
    data,
  )
}
function updateAddress(data) {
  return axios.put(
    apiConfig.save_address_request,
    data,
  )
}

function getStoreDetails(data) {
  return axios.get(
    apiConfig.get_store_details,
    data,

  )
}

function otpRequest(data) {
  return axios.post(
    apiConfig.otp_request,
    data,
  )
}

function placeOrder(data) {
  return axios.post(
    apiConfig.place_order,
    data,
  )
}

function setOutlet(id) {
  return axios.post(
    apiConfig.set_outlet, { id },
  )
}
