<template>

  <v-overlay
    :value="blockStore"
    class="sheet-overlay"
  >
    <div>
      <img
        class="srvc-svg"
        src="../assets/images/service_unavailable.svg"
      >
    </div>

    <p>Service not available at this moment</p>
  </v-overlay>

</template>

<script>
import {
  ref, watch,
} from '@vue/composition-api'

export default {
  components: {

  },
  props: {
    availability: {
      type: Boolean,
      required: true,
    },

  },
  setup(props) {
    const blockStore = ref(props.availability)

    watch(() => props.availability, availability => {
      blockStore.value = availability
    })
    return {
      blockStore,
    }
  },
  computed: {

  },

}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.block-store .header .second-header {
  filter: opacity(0.5);
  pointer-events: none;
  /* position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(40 44 63 / 18%);
    background-blend-mode: overlay;
    opacity: 30%; */
  /* filter: invert(1); */
  /* filter: blur(5px); */
}
.sheet-overlay p {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.srvc-svg {
  text-align: center !important;
  width: 150px;
  display: block;
  margin: auto;
  max-height: 200px;
}
</style>
<style>
.v-overlay__scrim {
  background-color: rgb(0 0 0) !important;
}
</style>
